import {getUrlParams} from "@/common/url";
import {site_config} from "@/config";
import axios from "axios";

export const VideoMixins = {
	data(){
		return {
			file:[],
			play_index:false,
			tip_index:false,
			show_tip:true,
		}
	},
	mounted() {
		this.getData();
	},
	methods:{
		async getData(){
			const query = this.$route.query;
			const version = getUrlParams('version')
			const host = site_config.json_host;
			let json_url;
			if(version){
				json_url = host + '/' + version;
			}else{
				const version_url = host+'/version.json';
				const version_data = await axios.get(version_url);
				json_url = version_data?.data?.url;
			}

			const json = await axios.get(json_url);
			const {file} = json?.data;

			this.file = file;
		},
		cover(item){
			return item.cover ? item.cover : item.video ? item.video+"?x-oss-process=video/snapshot,t_3000" : "";
		},
		playVideo(item,index){
			if(this.tip_index === false){
				this.tip_index = index;
				return false;
			}
			this.show_tip = false;
			this.stopAllVideo();
			const video = document.getElementById('video-'+item.id);
			if(video){
				video.play();
				video.addEventListener('play',()=>{
					this.play_index = index;
				})
				video.addEventListener('pause',()=>{
					this.play_index = false;
				})
			}
		},
		stopAllVideo(){
			this.video.map((item,index)=>{
				const video = document.getElementById('video-'+item.id);
				if(video){
					video.pause();
				}
			})
		}
	}
}
