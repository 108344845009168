<template>
	<main>
		<div class="section s5">
			<a-section class="section-wrap">
				<div class="content-box">
					<div class="title-box">
						<div class="title">白皮书下载</div>
					</div>
					<div class="list">
						<a-link :to="item.source" class="item" v-for="(item,index) in file" :key="index">
							<div class="icon"></div>
							<div class="info">
								<div class="title">{{item.title}}</div>
								<div class="btn">下载</div>
							</div>
						</a-link>
					</div>
				</div>
			</a-section>
		</div>
	</main>
</template>

<script>
import {VideoMixins} from "@/service/views/video";
export default {
	mixins:[VideoMixins]
}
</script>
<style lang="scss" scoped>
@import "./style";
</style>
